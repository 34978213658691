import React from 'react';
import { useAddress } from '@thirdweb-dev/react';
import Image from 'next/image';
import {useAppSelector} from '../../store/store';
import Link from 'next/link';

export default function ProfileMenu() {
    const address = useAddress();
    const accountStats = useAppSelector((state) => state.stats.stats);
    return (
      <>
        {address && (
        <Link href='/profile' className='relative group lg:w-12 lg:h-12 w-8 h-8 rounded-full bg-profileBack text-white cursor-pointer'>
          <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:w-6 lg:h-6 w-4 h-4'>
            <Image
                src={'/icons/profile.svg'}
                fill
                alt='Logo'
                loading='lazy'
            />
          </div>


          <div className='absolute w-full top-full transform p-4 min-w-[200px] bg-surfaceLow rounded-xl shadow-lg opacity-0 pointer-events-none transition-opacity duration-300 group-hover:opacity-100 mt-4 lg:right-[-15px] right-0'>
            <div className='flex flex-col gap-4 text-center'>
              {accountStats.slice(0, 5).map((item) => (
                <div className='flex items-center justify-between gap-4' key={item.name}>
                  <div className='flex gap-4'>
                    <Image
                        src={`/icons/${item.icon}`}
                        className='aspect-[1/1]'
                        style={{
                            position: 'relative'
                        }}
                        width={24}
                        height={24}
                        alt='Logo'
                        loading='lazy'
                    />
                    <div className='text-white flex-shrink-0 whitespace-nowrap text-[14px] font-ubuntu'>
                      {item.name}
                    </div>
                  </div>
                  <div className='text-white pl-4'>{item.count}</div>
                </div>
                ))}
            </div>
          </div>
        </Link>
        )}
      </>
    );
}
