import {etherToWei, weiToEther} from '../utils/global';

export const approve = async (amber: any, bet: number) => {
  const inWei = etherToWei(bet.toString());
  return await amber.call('approve', [process.env.NEXT_PUBLIC_BATTLE_TOKEN, inWei]);
};

export const getAmberToken = async (amber: any, address: string) => {
  const amberToken = await amber.call('balanceOf', [address])
  return Number(weiToEther(amberToken)).toFixed(0)
};