import React from 'react';
import Link from 'next/link';
import {MenuItem, menuItems} from '../../utils/menu.type';
import {usePathname} from 'next/navigation';
import Image from 'next/image';

const MenuItemComponent: React.FC<{ item: MenuItem }> = ({ item }) => {
    const pathname = usePathname();

    const checkActivePath = ((href: string) => {
        if (href === '/') {
            return href === pathname
        } else {
            const regex = new RegExp(`^${href}`);
            return regex.test(pathname)
        }
    })

    return (
      <div className='relative group'>
        <Link href={item.href} className='text-white font-ubuntu 2xl:text-[20px] text-[14px] font-bold focus:outline-none'
          onClick={({currentTarget}) => {currentTarget.blur();}}
        >
          <span className="sr-only">Navigate into {item.name}</span>
          <div className={`${checkActivePath(item.href)
            ? 'rounded-lg bg-surfaceTop'
            : 'rounded-lg hover:bg-white hover:bg-opacity-10 transition duration-300 ease-in-out'} px-6 h-[55px] flex gap-1 items-center group-focus-within:bg-white group-focus-within:bg-opacity-10`}
            >
            {item.name}
            {item.subMenu && (
              <span>
                <Image
                    className={'group-hover:rotate-180 transition duration-300 ease-in-out'}
                   src={'/icons/expand.svg'}
                   alt='background image'
                   width={16}
                   height={16}
                   loading='lazy'
               />
              </span>
              )}
          </div>
        </Link>

        {item.subMenu && (
          <div className='mt-1 pointer-events-none opacity-0 transition-all duration-500 ease-in-out group-focus-within:pointer-events-auto group-focus-within:opacity-100 group-hover:pointer-events-auto group-hover:opacity-100 '>
            <div className='absolute top-full left-1/2 transform -translate-x-1/2 min-w-[160px] bg-surfaceLow rounded-lg' >
              {item.subMenu.map((subItem) => (
                <Link href={subItem.href} key={subItem.name} className={`${ checkActivePath(subItem.href)
                    ? 'rounded-lg bg-surfaceTop'
                    : 'rounded-lg hover:bg-white hover:bg-opacity-10' } block transition duration-300 ease-in-out w-full px-4 py-4 text-center text-white font-ubuntu 2xl:text-[16px] text-[14px] focus:outline-none focus-within:bg-white focus-within:bg-opacity-10 `}
                      onClick={({currentTarget}) => {currentTarget.blur()}}>
                  {subItem.name}
                </Link>
              ))}
            </div>
          </div>
          )}
      </div>
    );
};

export default function Menu() {
    return (
      <div className='flex 2xl:flex justify-center items-start cursor-pointer'>
        {menuItems.map((item) => (
          <MenuItemComponent key={item.name} item={item} />
        ))}
      </div>
    );
}