import React from 'react';
import AmberStats from '../elements/amberStats';

import { MobileMenu } from '../elements/mobileMenu';
import { ConnectWallet } from '@thirdweb-dev/react';

import Logo from '../elements/logo';
import Menu from '../elements/menu';
import ProfileMenu from '../elements/profileMenu';

export default function Header() {

  return (
    <div className='lg:fixed top-0 left-0 right-0 lg:p-4 z-50'>
      <div className='hidden xl:flex justify-between w-full bg-surfaceNormal p-2 px-4 xl:rounded-xl bg-opacity-90 backdrop-blur-sm'>
        <Logo />
        <div className='flex 2xl:flex justify-center items-start cursor-pointer'>
          <Menu />
        </div>
        <div className='flex 2xl:flex-1 items-center justify-end gap-4'>
          <AmberStats />
          <ConnectWallet
            switchToActiveChain={true}
            className='connectWallet'
            theme='dark'
            btnTitle='Connect Wallet'
            modalTitle='Login to Decision Game'
          />
          <ProfileMenu />
        </div>
      </div>

      <div className='flex xl:hidden justify-between items-center w-full bg-surfaceNormal p-2 lg:rounded-xl'>
        <MobileMenu />
        <ProfileMenu />
      </div>
    </div>
  );
}
